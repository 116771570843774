import { FC, SVGProps } from 'react';

const MouseIcon: FC<SVGProps<SVGElement>> = ({
  color = '#E6E1DD',
  width = '40',
  height = '40',
}) => {
  return (
    <svg fill='none' height={height} viewBox='0 0 28 40' width={width}>
      <rect
        height='38.5'
        rx='13.25'
        stroke={color}
        strokeOpacity='0.7'
        strokeWidth='1.5'
        width='26.5'
        x='0.75'
        y='0.75'
      />
      <rect
        fill={color}
        fillOpacity='0.7'
        height='12'
        width='2'
        x='13'
        y='10'
      />
    </svg>
  );
};

export default MouseIcon;
