import React, { FC, useState, Children } from 'react';

import { Box, BoxProps, Flex } from '@chakra-ui/react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BackButton, NextButton } from '@/elements';
import useMediaQuery from '@/hooks/mediaQuery';

const showSlidesBigScreen = 2;
const showSlidesSmallScreen = 1;

interface ICarouselProps {
  children: JSX.Element | JSX.Element[];
}

const Carousel: FC<ICarouselProps & BoxProps> = ({ children, ...props }) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [index, setIndex] = useState(0);

  const isSmallScreen = useMediaQuery(['(max-width: 576px)']);

  const beforeChange = (prev, next) => {
    setIndex(next);
  };

  const sliderSettings = {
    arrows: false,
    infinite: false,
    slidesToShow: showSlidesBigScreen,
    slidesToScroll: 1,
    beforeChange,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: showSlidesSmallScreen,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Flex flexDir={isSmallScreen ? 'column-reverse' : 'column'} {...props}>
      <Box
        display='flex'
        justifyContent={isSmallScreen ? 'center' : 'flex-end'}
        m={isSmallScreen ? '48px 0 0 0' : '0 0 30px 0'}
      >
        <BackButton disabled={index === 0} onClick={sliderRef?.slickPrev}>
          Prev
        </BackButton>
        <NextButton
          disabled={
            isSmallScreen
              ? index ===
                Children.toArray(children).length - showSlidesSmallScreen
              : index ===
                Children.toArray(children).length - showSlidesBigScreen
          }
          onClick={sliderRef?.slickNext}
        >
          Next
        </NextButton>
      </Box>
      <Slider ref={setSliderRef} {...sliderSettings}>
        {children}
      </Slider>
    </Flex>
  );
};

export default Carousel;
