import React, { FC } from 'react';

import { Box, BoxProps, useTheme } from '@chakra-ui/react';
import { motion, MotionProps } from 'framer-motion';

import { Header, Footer } from '../Layout';

const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

const Page: FC<
  BoxProps &
    MotionProps & {
      withHeader?: boolean;
      withFooter?: boolean;
    }
> = ({ children, withHeader, withFooter, ...mainProps }) => {
  const theme = useTheme();

  return (
    <>
      {withHeader && <Header />}
      <Box
        animate='enter'
        as={motion.main}
        display='flex'
        exit='exit'
        flexDir='column'
        initial='hidden'
        paddingTop={
          withHeader && {
            base: `${theme.header.mobileHeight}px`,
            md: `${theme.header.height}px`,
          }
        }
        position='relative'
        variants={variants}
        width='100%'
        {...mainProps}
      >
        <Box height='100%'>{children}</Box>
      </Box>
      {withFooter && <Footer />}
    </>
  );
};

export default Page;
