import React, { FC } from 'react';

import { Center, Box, StackProps, Stack, VStack, Flex } from '@chakra-ui/react';


import BodyFatIcon from '@/components/Icons/BodyFatIcon';
import InsufficientHairlineIcon from '@/components/Icons/InsufficientHairlineIcon';
import LibidoIcon from '@/components/Icons/LibidoIcon';
import MoreSymptomsIcon from '@/components/Icons/MoreSymptomsIcon';
import { BigTitle, Subtitle, P, Link } from '@/elements';
import useMediaQuery from '@/hooks/mediaQuery';

const TextIcon = (props) => {
  return (
    <Box
      bg='teal.500'
      border='1px solid #009FAF'
      boxShadow='0px 0px 11px rgba(14, 209, 251, 0.7)'
      flex='0 0 auto'
      h='8px'
      position='absolute'
      transform='rotate(45deg)'
      w='8px'
      {...props}
    />
  );
};

const Symptoms: FC<StackProps> = (props) => {
  const isSmallScreen = useMediaQuery(['(max-width: 576px)']);

  const containerSize = isSmallScreen ? 60 : 170;
  const iconSize = isSmallScreen ? 100 : 194;

  return (
    <Stack
      direction={{ xs: 'column', xl: 'row' }}
      justifyContent='space-around'
      spacing='xl'
      {...props}
    >
      {!isSmallScreen && (
        <Center
          alignSelf={{ xs: 'center', xl: 'flex-start' }}
          flexDirection='column'
          maxW='500px'
          mb={{ xs: 'xxxl', xl: 0 }}
          pl='lg'
          w={{ xs: '100%', xl: '50%' }}
        >
          <Stack
            direction='row'
            flexWrap='wrap'
            spacing='0px'
            transform='rotate(-45deg)'
          >
            <Box h={containerSize} transform='rotate(45deg)' w={containerSize}>
              <BodyFatIcon height={iconSize} width={iconSize} />

              <TextIcon left='20px' top='20px' />
              <P
                color='white.100'
                fontSize='15px'
                left='-60px'
                maxW='100px'
                position='absolute'
                top='13px'
              >
                Increased body fat
              </P>
            </Box>

            <Box h={containerSize} transform='rotate(45deg)' w={containerSize}>
              <LibidoIcon height={iconSize} width={iconSize} />
              <TextIcon right='0px' top='20px' />
              <P
                color='white.100'
                fontSize='15px'
                position='absolute'
                right='-115px'
                top='14px'
              >
                Reduced libido
              </P>
            </Box>

            <Box h={containerSize} transform='rotate(45deg)' w={containerSize}>
              <MoreSymptomsIcon />
            </Box>

            <Box
              h={containerSize}
              position='relative'
              transform='rotate(45deg)'
              w={containerSize}
            >
              <InsufficientHairlineIcon height={iconSize} width={iconSize} />
              <TextIcon bottom='0px' right='0px' />
              <P
                bottom='-30px'
                color='white.100'
                fontSize='15px'
                maxW='100px'
                position='absolute'
                right='-113px'
              >
                Insufficient hairline
              </P>
            </Box>
          </Stack>
        </Center>
      )}

      <Box
        maxW={{ xs: '100%', md: '600px', xl: '480px' }}
        w={{ xs: '100%', xl: '50%' }}
      >
        <Subtitle fontSize={{ xs: '14px', md: 'base' }} mb='32px'>
          CHECK YOURSELF
        </Subtitle>

        <BigTitle fontSize={{ xs: '32px', md: '40px', lg: 'xl' }} mb='semiXl'>
          Do you have any of these{' '}
          <Box color='teal.500' display='inline-flex'>
            symptoms
          </Box>{' '}
        </BigTitle>

        {isSmallScreen && (
          <VStack mb='semiXl'>
            <Flex alignItems='center' w='100%'>
              <LibidoIcon height={iconSize} width={iconSize} />
              <P color='white.100' ml='md'>
                Reduced libido
              </P>
            </Flex>
            <Flex alignItems='center' w='100%'>
              <BodyFatIcon height={iconSize} width={iconSize} />
              <P color='white.100' ml='md'>
                Increased body fat
              </P>
            </Flex>
            <Flex alignItems='center' w='100%'>
              <InsufficientHairlineIcon height={iconSize} width={iconSize} />
              <P color='white.100' ml='md'>
                Insufficient hairline
              </P>
            </Flex>
          </VStack>
        )}

        <P>
          If yes, you may have low testosterone. We recommend you{' '}
          <Link
            color='white.100'
            href='#get-started'
            textDecoration='underline'
          >
            take a blood test at home
          </Link>{' '}
          to find out.
        </P>
      </Box>
    </Stack>
  );
};

export default Symptoms;
