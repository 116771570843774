import React from 'react';

import Benefit from '@/components/Benefit';

const Benefits = () => {
  return (
    <>
      <Benefit
        align='flex-end'
        description='It’s reassuring to know that our doctors will only ever prescribe treatments that uses high quality, UK licenced medications.'
        title='UK licensed medications'
      />
      <Benefit
        align='flex-start'
        description='Because Ted’s Health take a more personal approach, our doctors are available beyond the initial prescription stage, here to ensure your ongoing wellness.'
        title='Ongoing doctor support'
      />
      <Benefit
        align='flex-end'
        description='Ted’s Health provide treatment and testing by post and consultations via video, so it’s more discreet and you never have to make time to attend a doctor’s office.'
        title='No waiting rooms'
      />
      <Benefit
        align='flex-start'
        description='We’ll never charge you for sending a test or a treatment. That’s Ted’s way.'
        title='Free delivery'
      />
      <Benefit
        align='flex-end'
        description='After assessing your tests and consulting with you, your Ted’s Health doctor will create a personal treatment plan matched to your symptoms.'
        title='Personal treatment plan'
      />
      <Benefit
        align='flex-start'
        description='You’re free to walk away from our treatment plans anytime. There’s no locking-in or long term commitment.'
        title='Cancel anytime'
      />
    </>
  );
};

export default Benefits;
