import React, { FC } from 'react';

import { Box, Center, Flex, Stack, Text } from '@chakra-ui/react';

import { BigTitle, P } from '@/elements';

const Block = ({ title, description }) => {
  return (
    <Flex flexDir='row' maxW={{ base: '100%', sm: '380px' }} mb='72px'>
      <Box
        backgroundColor='teal.500'
        borderRadius='12px'
        height='64px'
        mr='34px'
        w='4'
      />
      <Flex flexDir='column'>
        <Text
          color='brand.dark'
          fontSize='20px'
          fontWeight='600'
          lineHeight='24px'
          mb='16px'
        >
          {title}
        </Text>
        <P color='brand.dark'>{description}</P>
      </Flex>
    </Flex>
  );
};

const WhatToExpect: FC = () => {
  return (
    <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={0}>
      <Center
        alignItems={{ xs: 'flex-start', md: 'center' }}
        bg='brand.light'
        color='brand.dark'
        flexDir='column'
        maxW='400px'
        minW={{ xs: '100%', md: '50%' }}
        pb={{ xs: 'xl', md: 0 }}
        pt={{ xs: '40px', md: 0 }}
        px={{ xs: 'semiLg', md: 0 }}
      >
        <Box>
          <BigTitle
            fontSize={{ xs: '32px', md: '40px', lg: 'xl' }}
            mb='semiXl'
            mt={{ xs: 'lg', md: 'xl' }}
          >
            What to expect?
          </BigTitle>

          <Block
            description='Most men find they’re able to engage in work for longer periods of time, as well as having improved recall, and more meaningful conversations.'
            title='Increased focus'
          />

          <Block
            description='Fitness-wise, you can expect to manage longer workouts with a faster recovery time, and to shed body fat while increasing bone density.'
            title='Better strength'
          />

          <Block
            description='Most patients find they begin to complete more tasks in a day, wake up feeling more refreshed, have fewer mood swings and improved self esteem.'
            title='More energy'
          />
        </Box>
      </Center>

      <Box
        bg='url(images/what-to-expect.png)'
        bgPos='top'
        bgSize='cover'
        minH='500px'
        minW={{ xs: '100%', md: '50%' }}
      />
    </Stack>
  );
};

export default WhatToExpect;
