import React, { FC } from 'react';

import { Box, Center, Flex, Image } from '@chakra-ui/react';

import DiscountBadge from '@/components/Icons/DiscountBadge';
import ProgressSteps from '@/components/LandingPage/ProgressSteps';
import { Button, H2, H3, Link, PriceTag, Title } from '@/elements';

const GetStarted: FC<{ startNow: () => void }> = ({ startNow }) => {
  return (
    <Flex flexDir={{ xs: 'column', xl: 'row' }} flexWrap='wrap'>
      <Box flexGrow={1} mb='xxxl'>
        <H2 fontSize='48px' lineHeight='64px' mb='80px'>
          How to get started?
        </H2>
        <Box maxW='436px' mx={{ xs: 'auto', xl: 0 }} position='relative'>
          <ProgressSteps />
        </Box>
        <H3
          fontSize={26}
          fontWeight={100}
          lineHeight='32px'
          textDecoration='underline'
        >
          <Link href='#faq'>Have a question?</Link>
        </H3>
      </Box>
      <Box flexGrow={1} mb='xxxl' mx={{ xs: 'auto', xl: 0 }}>
        <Box
          background='linear-gradient(143.08deg, #2C2B2B 0%, #232323 39.06%);'
          boxShadow='2xl'
          maxW='560px'
          position='relative'
        >
          {/** replace with version from stripe */}
          <Image src='images/blood-test.png' />
          <Box
            mt={{ xs: '-80px', md: '-100px', lg: '-130px' }}
            px={{ xs: 'lg', lg: 'xl' }}
            py='lg'
            width='100%'
          >
            <Title mb='27px'>Take a blood test at home</Title>
            <Flex alignItems='baseline' mb='36px'>
              <PriceTag fontSize={{ base: '36px', md: '48px' }} mr='24px'>
                {Intl.NumberFormat('en-GB', {
                  style: 'currency',
                  currency: 'gbp',
                }).format(50)}
              </PriceTag>
              <PriceTag discounted fontSize={{ base: '18px', md: '26px' }}>
                £130.00
              </PriceTag>
            </Flex>
            <Center mb={{ xs: '40px', md: '60px', lg: '80px' }} w='100%'>
              <Button
                boxShadow='0px 3.68101px 40.4911px rgba(3, 101, 111, 0.16);'
                data-cy='landingPageBuyNow'
                onClick={startNow}
                w='100%'
              >
                Buy now
              </Button>
            </Center>
          </Box>
          <Box
            height={{ base: '120px', md: '176px' }}
            position='absolute'
            right={{ base: '-15px', md: '-45px' }}
            top='-35px'
          >
            <DiscountBadge />
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default GetStarted;
