import { FC } from 'react';

import { AccordionProps } from '@chakra-ui/react';

import Accordion from '@/components/Accordion/Accordion';

const FAQAccordion: FC<AccordionProps> = (props) => {
  return (
    <Accordion
      items={[
        {
          title: 'What is Testosterone Deficiency (TD)?',
          content: [
            `Testosterone deficiency, also known as hypogonadism, is a condition
          where a man cannot produce sufficient levels of the primary male
          hormone, testosterone. Testosterone levels tend to deplete as men age,
          and studies show they lose around 1% of their testosterone level every
          year from the age of 30.`,
            `Other conditions that can cause low
          testosterone levels include type 2 diabetes, obesity, and long-term
          use of certain opiate medications like codeine.`,
          ],
        },
        {
          title: 'What are the key symptoms of TD?',
          content: [
            'Symptoms of TD include reduced libido, erectile dysfunction, poor concentration, low mood, fatigue,  increased body fat and reduced muscle mass. Sadly, the effects can be more than physical, and create significant impact on relationships and performance at work.',
          ],
        },
        {
          title: 'How do you test for TD?',
          content: [
            'To qualify for treatment, you need to take two blood tests, complete our online questionnaire and have a video consultation with one of our doctors. Your doctor will then monitor your testosterone levels to ensure your treatment is working.',
          ],
        },
        {
          title: 'How common is TD?',
          content: [
            'Around 1 in 4 men over 30  may be deficient in testosterone. Testosterone levels are thought to decrease by around 1% per year from that age.',
          ],
        },
        {
          title: 'How do TD medications work?',
          content: [
            'Most treatments take the form of a gel applied once a day to the abdomen, shoulders or arms. Others involve long-acting testosterone injections administered by a health professional four times a year. You should typically start to see an improvement of your symptoms within 6 months of use.',
          ],
        },
        {
          title: 'Is it safe and will there be side-effects?',
          content: [
            'Testosterone Replacement Therapy (TRT) is generally well tolerated and fully licensed for use in the United Kingdom. There are certain medical conditions where therapy must not be taken. Your doctor will asses these during your consultation.',
          ],
        },
        {
          title: 'Will I be able to talk to a licenced doctor?',
          content: [
            'Once you have done your blood test and received your results, our dashboard will allow you to schedule in a full video consultation with a GP. Your doctor will discuss your suitability for treatment and answer any questions.',
          ],
        },
        {
          title: 'Is it legal to purchase testosterone therapy online?',
          content: [
            'Yes - as long as you meet the full eligibility requirements and your ID check is approved, one of our fully licenced doctors will prescribe medication in compliance with all UK laws.',
          ],
        },
        {
          title: 'What is Tadalafil and why have I been recommended this?',
          content: [
            'Erectile dysfunction (ED) is one of the key symptoms of low testosterone, so taking a medication alongside testosterone therapy to treat ED, raises testosterone levels and strongly improves treatment satisfaction.',
            'Tadalafil can be taken as a daily medication so you don’t have to plan around sex, and the effects last around 36 hours. It causes increased blood flow to the penis, for longer-lasting, stronger erections. ',
          ],
        },
      ]}
      {...props}
    />
  );
};

export default FAQAccordion;
