/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';

import { Center, Flex, Image, Text } from '@chakra-ui/react';

import { Card, P, Title } from '@/elements';

import StarIcon from '../Icons/StarIcon';

const maxRating = 5;

interface ICustomerReviewProps {
  imgSource: string;
  name: string;
  age: number;
  review: string;
  rating?: number;
}

const CustomerReview: FC<ICustomerReviewProps> = ({
  imgSource = 'images/profile-avatar-1.png',
  name = 'John Doe',
  age = 35,
  review,
  rating = 5,
}) => {
  return (
    <Card
      h={{ xs: '550px', lg: '500px' }}
      mr='30px'
      mt='41px'
      px={{ xs: '40px', lg: 'xxl' }}
      py={{ xs: 'semiLg', lg: 'xxl' }}
      w='auto'
    >
      <Center flexDir='column'>
        <Image
          alt='customer avatar'
          borderRadius='100%'
          h='112px'
          mt={{ xs: '-64px', lg: '-136px' }}
          src={imgSource}
          w='112px'
        />
        <Title mb='12px' mt='lg' style={{ textTransform: 'capitalize' }}>
          {name}
        </Title>
        <Text
          color='white.70'
          fontSize={17}
          fontWeight={400}
          letterSpacing='-2%'
          lineHeight='28px'
          mb='28px'
        >
          Age: {age}
        </Text>
        <Flex flexDir='row' gap='2'>
          {Array.from(Array(rating)).map((r, index) => (
            <StarIcon key={index} />
          ))}
          {Array.from(Array(maxRating - rating)).map((r, index) => (
            <StarIcon key={index} color='rgba(230, 225, 221, 0.7)' />
          ))}
        </Flex>
        <P maxW='554px' mt='40px'>
          {review}
        </P>
      </Center>
    </Card>
  );
};

export default CustomerReview;
