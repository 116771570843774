import React from 'react';

import ProgressStep from '@/components/ProgressStep/ProgressStep';
import { Link } from '@/elements';

const ProgressSteps = () => {
  return (
    <>
      <ProgressStep
        description='We’ll send you an easy-to-use blood test to take at home.'
        isActive
        link={<Link href='#blood-test-faq'>Find out more</Link>}
        number={1}
        title='Take a blood test'
      />
      <ProgressStep
        description='As soon as we’ve got your test results we’ll book you in for a  personal video consultation with one of our doctors.'
        number={2}
        title='Personal consultation'
      />
      <ProgressStep
        description='Your doctor will prescribe the right treatment for you, with ongoing careful monitoring.'
        lastStep
        number={3}
        title='Individual treatment'
      />
    </>
  );
};

export default ProgressSteps;
