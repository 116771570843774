import React, { FC } from 'react';

import { Center, Box, Stack } from '@chakra-ui/react';

import BSSMLogoIcon from '@/components/Icons/BSSMLogoIcon';
import { BigTitle, P } from '@/elements';

const TextContainer = ({ children }) => {
  return (
    <Center
      alignItems={{ xs: 'flex-start', md: 'center' }}
      bg='brand.light'
      color='brand.dark'
      flexDir='column'
      maxW='400px'
      minW={{ xs: '100%', md: '50%' }}
      pb={{ xs: 'xl', lg: 0 }}
      pt={{ xs: '40px', lg: 0 }}
      px={{ xs: 'semiLg', lg: 0 }}
    >
      {children}
    </Center>
  );
};

const Treatments: FC = () => {
  return (
    <>
      <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={0}>
        <TextContainer>
          <Box>
            <BigTitle
              fontSize={{ xs: '44px', md: '58px', lg: 'xxl' }}
              mb='semiXl'
            >
              Treatments
            </BigTitle>

            <P color='brand.dark' maxW='360px' mb='xl'>
              Our specialists will only ever prescribe proven and approved UK
              licenced medications. All our treatments and medicines are
              approved by the following sexual health authorities.
            </P>

            <Center border='1px solid #D7CBC3' h={132} w={132}>
              <BSSMLogoIcon />
            </Center>
          </Box>
        </TextContainer>

        <Box
          bg='url(images/treatments.png)'
          bgPos='center'
          bgSize='cover'
          minH={{
            xs: '400px',
            sm: '500px',
            lg: '600px',
            xl: '700px',
            xxl: '990px',
          }}
          minW={{ xs: '100%', md: '50%' }}
        />
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={0}>
        <Box
          bg='url(images/testosterone.png)'
          bgPos='center'
          bgSize='cover'
          minH={{ xs: '550px', md: '700px', lg: '800px', xl: '900px' }}
          minW={{ xs: '100%', md: '50%' }}
        />

        <TextContainer>
          <Box>
            <BigTitle
              fontSize={{ xs: '32px', md: '40px', lg: 'xl' }}
              maxW='440px'
              mb='semiXl'
            >
              Testosterone treatment packages
            </BigTitle>

            <P color='brand.dark' maxW='440px' mb='xl'>
              Our testosterone replacement treatment packages are bespoke and
              individually tailored to you. Therapies can come in many forms
              such as a daily gel, or long-acting injections.
            </P>
          </Box>
        </TextContainer>
      </Stack>
    </>
  );
};

export default Treatments;
