import React, { FC } from 'react';

import { VStack } from '@chakra-ui/react';

import Carousel from '@/components/Carousel/Carousel';
import Specialist from '@/components/LandingPage/Specialists/Specialist';
import useMediaQuery from '@/hooks/mediaQuery';

const Container = ({ isSmallScreen, children }) => {
  if (isSmallScreen) {
    return <Carousel mb='xl'>{children}</Carousel>;
  }

  return <VStack spacing='xxl'>{children}</VStack>;
};

const Specialists: FC = () => {
  const isSmallScreen = useMediaQuery(['(max-width: 576px)']);

  return (
    <Container isSmallScreen={isSmallScreen}>
      <Specialist
        avatar='images/specialist-avatar-1.png'
        bio={
          "Dr. Shaan Patel is the Medical Director of Ted's Health and Aatma Aesthetics. He is a Bachelor of Medicine & Bachelor of Surgery degrees (BMBS). Throughout his medical career he has worked across multiple disciplines, including; Vascular Surgery, Community Psychiatry, Diabetes and Endocrinology."
        }
        isSmallScreen={isSmallScreen}
        name='Dr Shaan Patel'
        position='Medical director'
        // socials={[
        //   { type: SocialLinkType.Facebook, href: 'https://www.google.com' },
        //   { type: SocialLinkType.Twitter, href: 'https://www.google.com' },
        //   { type: SocialLinkType.LinkedIn, href: 'https://www.google.com' },
        // ]}
      />
      <Specialist
        avatar='images/specialist-avatar-2.jpeg'
        bio='Geoff Hackett is past professor of Men’s Health and Diabetes at University of Bedfordshire, and a Consultant in Urology and Sexual Medicine at Good Hope Hospital. He achieved his MD at Keele University on the links between erectile dysfunction (ED) and cardiovascular disease.'
        isSmallScreen={isSmallScreen}
        name='Geoff Hackett'
        position='Professor, MD FRCPI'
        // socials={[
        //   { type: SocialLinkType.Twitter, href: 'https://www.google.com' },
        //   { type: SocialLinkType.Instagram, href: 'https://www.google.com' },
        // ]}
      />
    </Container>
  );
};

export default Specialists;
