import { FC, SVGProps } from 'react';

const LinkedIn: FC<SVGProps<SVGElement>> = ({
  width = 32,
  height = 32,
  color = '#6F6F6F',
}) => {
  return (
    <svg
      fill='none'
      height={height}
      viewBox='0 0 32 32'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM7.68347 13.252H11.3094V24.1465H7.68347V13.252ZM11.5459 9.8818C11.5224 8.8136 10.7585 8 9.51809 8C8.27767 8 7.46673 8.8136 7.46673 9.8818C7.46673 10.9279 8.25371 11.7649 9.47102 11.7649H9.49419C10.7585 11.7649 11.5459 10.9279 11.5459 9.8818ZM20.2092 12.996C22.5952 12.996 24.384 14.5534 24.384 17.8997L24.3839 24.1463H20.7581V18.3177C20.7581 16.8537 20.2334 15.8547 18.9208 15.8547C17.919 15.8547 17.3224 16.5282 17.0603 17.1787C16.9644 17.4118 16.9409 17.7366 16.9409 18.0622V24.1466H13.3145C13.3145 24.1466 13.3623 14.2744 13.3145 13.2521H16.9409V14.7952C17.4221 14.0534 18.2841 12.996 20.2092 12.996Z'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  );
};

export default LinkedIn;
