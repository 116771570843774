import { FC, SVGProps } from 'react';

const MoreSymptomsIcon: FC<SVGProps<SVGElement>> = ({
  width = '194',
  height = '194',
}) => {
  return (
    <svg
      fill='none'
      height={height}
      viewBox='0 0 172 172'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        fill='#1F1E1E'
        height='121.129'
        transform='rotate(-45 0 85.9999)'
        width='121.129'
        y='85.9999'
      />
      <g>
        <rect
          height='120.031'
          stroke='#E6E1DD'
          strokeOpacity='0.05'
          strokeWidth='1.09809'
          transform='rotate(-45 0.776469 85.9999)'
          width='120.031'
          x='0.776469'
          y='85.9999'
        />
        <text fill='#E6E1DD' fontSize='16' textAnchor='middle' x='50%' y='50%'>
          <tspan dy='-10' x='50%'>
            +8
          </tspan>
          <tspan dy='20' x='50%'>
            more
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default MoreSymptomsIcon;
