import { Icon } from '@chakra-ui/react';

const ChevronDown = (props) => {
  return (
    <Icon fill='none' viewBox='0 0 20 12' {...props}>
      <path
        d='M19 1L10 10L1 0.999999'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </Icon>
  );
};

export default ChevronDown;
