import { FC, SVGProps } from 'react';

const TwitterIcon: FC<SVGProps<SVGElement>> = ({
  width = 32,
  height = 32,
  color = '#6F6F6F',
}) => {
  return (
    <svg
      fill='none'
      height={height}
      viewBox='0 0 32 32'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM12.4822 7.51822C13.3924 7.4768 13.6833 7.46667 16.0008 7.46667H15.9981C18.3164 7.46667 18.6062 7.4768 19.5164 7.51822C20.4248 7.55982 21.0453 7.70365 21.5893 7.91467C22.1511 8.13245 22.6258 8.42401 23.1004 8.89868C23.5751 9.373 23.8667 9.84909 24.0853 10.4103C24.2951 10.9529 24.4391 11.573 24.4818 12.4815C24.5227 13.3917 24.5333 13.6826 24.5333 16.0001C24.5333 18.3176 24.5227 18.6078 24.4818 19.518C24.4391 20.4261 24.2951 21.0464 24.0853 21.5891C23.8667 22.1502 23.5751 22.6263 23.1004 23.1006C22.6263 23.5753 22.1509 23.8675 21.5898 24.0855C21.0469 24.2965 20.4261 24.4404 19.5176 24.482C18.6074 24.5234 18.3174 24.5335 15.9997 24.5335C13.6824 24.5335 13.3917 24.5234 12.4815 24.482C11.5732 24.4404 10.9529 24.2965 10.41 24.0855C9.84909 23.8675 9.373 23.5753 8.89886 23.1006C8.42437 22.6263 8.13281 22.1502 7.91467 21.5889C7.70382 21.0464 7.56 20.4263 7.51822 19.5178C7.47698 18.6076 7.46667 18.3176 7.46667 16.0001C7.46667 13.6826 7.47733 13.3915 7.51804 12.4813C7.55893 11.5732 7.70294 10.9529 7.91449 10.4102C8.13316 9.84909 8.42472 9.373 8.89939 8.89868C9.37371 8.42419 9.8498 8.13263 10.4111 7.91467C10.9536 7.70365 11.5737 7.55982 12.4822 7.51822Z'
        fill={color}
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M15.2379 9.00409C15.3866 9.00385 15.5465 9.00393 15.719 9.004L16.0034 9.00409C18.2819 9.00409 18.5519 9.01226 19.4517 9.05315C20.2837 9.0912 20.7352 9.23022 21.036 9.34702C21.4343 9.50169 21.7182 9.68658 22.0167 9.98525C22.3153 10.2839 22.5002 10.5684 22.6552 10.9666C22.772 11.267 22.9112 11.7186 22.9491 12.5506C22.99 13.4502 22.9989 13.7204 22.9989 15.9978C22.9989 18.2751 22.99 18.5453 22.9491 19.4449C22.9111 20.2769 22.772 20.7285 22.6552 21.0289C22.5006 21.4271 22.3153 21.7107 22.0167 22.0092C21.718 22.3079 21.4344 22.4928 21.036 22.6474C20.7356 22.7648 20.2837 22.9034 19.4517 22.9415C18.5521 22.9824 18.2819 22.9913 16.0034 22.9913C13.7248 22.9913 13.4548 22.9824 12.5552 22.9415C11.7232 22.9031 11.2717 22.7641 10.9707 22.6473C10.5725 22.4926 10.288 22.3077 9.98933 22.009C9.69066 21.7104 9.50577 21.4266 9.35075 21.0282C9.23395 20.7278 9.09475 20.2762 9.05688 19.4442C9.01599 18.5446 9.00781 18.2744 9.00781 15.9956C9.00781 13.7168 9.01599 13.448 9.05688 12.5485C9.09492 11.7165 9.23395 11.2649 9.35075 10.9641C9.50542 10.5659 9.69066 10.2814 9.98933 9.98276C10.288 9.68409 10.5725 9.4992 10.9707 9.34418C11.2715 9.22684 11.7232 9.08818 12.5552 9.04995C13.3424 9.0144 13.6475 9.00373 15.2379 9.00195V9.00409ZM20.5584 10.4211C19.993 10.4211 19.5344 10.8792 19.5344 11.4447C19.5344 12.0101 19.993 12.4687 20.5584 12.4687C21.1237 12.4687 21.5824 12.0101 21.5824 11.4447C21.5824 10.8794 21.1237 10.4207 20.5584 10.4207V10.4211ZM11.6211 15.9995C11.6211 13.5794 13.5832 11.6173 16.0032 11.6172C18.4233 11.6172 20.3849 13.5793 20.3849 15.9995C20.3849 18.4196 18.4235 20.3808 16.0034 20.3808C13.5832 20.3808 11.6211 18.4196 11.6211 15.9995Z'
        fill={color}
        fillRule='evenodd'
      />
      <path
        clipRule='evenodd'
        d='M16.0007 13.1553C17.5716 13.1553 18.8452 14.4287 18.8452 15.9997C18.8452 17.5706 17.5716 18.8442 16.0007 18.8442C14.4297 18.8442 13.1562 17.5706 13.1562 15.9997C13.1562 14.4287 14.4297 13.1553 16.0007 13.1553V13.1553Z'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  );
};

export default TwitterIcon;
