import React from 'react';

import { Box, Image, useTheme } from '@chakra-ui/react';
import { useRouter } from 'next/router';


import MouseIcon from '@/components/Icons/MouseIcon';
import { Section } from '@/components/Layout';
import { Button, H1, Subtitle } from '@/elements';

const Video = () => {
  const theme = useTheme();
  const { push } = useRouter();

  const isVideoEnabled = true;

  return (
    <Box height='100vh' position='relative'>
      {isVideoEnabled ? (
        <Box
          height='100%'
          mt={{
            base: `-${theme.header.mobileHeight}px`,
            md: `-${theme.header.height}px`,
          }}
          position='absolute'
          width='100%'
          zIndex='-1'
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              objectFit: 'cover',
            }}
            title='promo-video'
          >
            <source src='videos/hero-video.mp4' type='video/mp4' />
          </video>
          <Box
            backgroundColor='rgba(0, 0, 0, 0.6)'
            h='100%'
            position='absolute'
            w='100%'
          />
        </Box>
      ) : (
        <Image
          alt='fallback'
          height='100%'
          mt={{
            base: `-${theme.header.mobileHeight}px`,
            md: `-${theme.header.height}px`,
          }}
          objectFit='cover'
          position='absolute'
          src='images/fallback.png'
          width='100%'
        />
      )}
      <Section>
        <Box maxW='500px'>
          <Subtitle
            mb='48px'
            mt={{
              base: 'xl',
              md: 0,
            }}
          >
            A MORE PERSONAL APPROACH TO /
          </Subtitle>
          <H1 mb='56px'>Testosterone deficiency</H1>
          <Subtitle mb='88px'>
            Talk to Ted to access friendly experts, here for you, whatever
            issues you’re facing. Let us create your personalised treatment
            plan, using only high quality UK licensed medications.
          </Subtitle>
          <Button onClick={() => push('/#get-started')}>Get started</Button>
        </Box>
      </Section>
      <Box
        bottom={{
          base: `calc(5vh + ${theme.header.mobileHeight}px)`,
          md: `calc(5vh + ${theme.header.height}px)`,
        }}
        left='50%'
        position='absolute'
        transform='translateX(-50%)'
      >
        <MouseIcon />
      </Box>
    </Box>
  );
};

export default Video;
