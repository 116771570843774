import React, { FC, useCallback } from 'react';

import { Box, Center, Flex, HStack, Image } from '@chakra-ui/react';

import FacebookIcon from '@/components/Icons/Social/FacebookIcon';
import InstagramIcon from '@/components/Icons/Social/InstagramIcon';
import LinkedInIcon from '@/components/Icons/Social/LinkedInIcon';
import TwitterIcon from '@/components/Icons/Social/TwitterIcon';
import { Card, Title, P, Link } from '@/elements';

// eslint-disable-next-line no-shadow
export enum SocialLinkType {
  Facebook = 'facebook',
  Twitter = 'twitter',
  LinkedIn = 'linkedIn',
  Instagram = 'instagram',
}

export interface ISocialLink {
  type: SocialLinkType;
  href: string;
}

const SocialLink = ({ href, icon }) => {
  return (
    <Link
      _hover={{ bg: 'white.100' }}
      alignItems='center'
      borderRadius='100%'
      display='flex'
      h='32px'
      href={href}
      isExternal
      justifyContent='center'
      w='32px'
    >
      {icon}
    </Link>
  );
};

const Specialist: FC<{
  name: string;
  position: string;
  bio: string;
  avatar: string;
  isSmallScreen: boolean;
  socials?: ISocialLink[];
}> = ({ name, position, bio, avatar, isSmallScreen, socials }) => {
  const renderSocialIcon = useCallback(
    (type: SocialLinkType) => {
      switch (type) {
        case SocialLinkType.Facebook:
          return <FacebookIcon />;
        case SocialLinkType.LinkedIn:
          return <LinkedInIcon />;
        case SocialLinkType.Twitter:
          return <TwitterIcon />;
        case SocialLinkType.Instagram:
          return <InstagramIcon />;
        default:
          return <></>;
      }
    },
    [socials]
  );

  return (
    <Flex textAlign='start'>
      {!isSmallScreen && (
        <Image
          alt='specialist photo'
          h='280px'
          mr='20px'
          src={avatar}
          transform='translateY(-20px)'
          w='280px'
        />
      )}

      <Card
        display='flex'
        flexDirection={{ xs: 'column', lg: 'row' }}
        maxW='850px'
        p={{ xs: 'semiLg', sm: 'lg', md: 'xl' }}
      >
        {isSmallScreen && (
          <Center mb='semiLg'>
            <Image alt='specialist photo' h='280px' src={avatar} w='280px' />
          </Center>
        )}
        <Box flex={1} justifyContent='space-between'>
          <Title fontSize='lg' mb='md'>
            {name}
          </Title>
          <P fontSize='15px'>{position}</P>
          <Flex mt='lg'>
            {socials?.length ? (
              <HStack spacing='20px'>
                {socials.map((s) => {
                  const icon = renderSocialIcon(s.type);

                  return (
                    <SocialLink
                      key={s.type}
                      href='https://www.google.com'
                      icon={icon}
                    />
                  );
                })}
              </HStack>
            ) : null}
          </Flex>
        </Box>
        <Flex flex={1} mt={{ xs: 'semiLg', lg: 0 }}>
          <P>{bio}</P>
        </Flex>
      </Card>
    </Flex>
  );
};

export default Specialist;
