import React, { FC, ReactNode } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { Description } from '@/elements';

interface IProgressStepProps {
  number: number;
  title: string;
  description: string;
  isActive?: boolean;
  lastStep?: boolean;
  link?: ReactNode;
}

const ProgressStep: FC<IProgressStepProps> = ({
  number,
  title,
  description,
  isActive,
  lastStep,
  link,
}) => {
  return (
    <Box key={`step-${title}`}>
      <Flex alignItems='center' mb='scale.md'>
        <Box
          backgroundColor={isActive ? 'teal.500' : ''}
          border='1px'
          borderColor={isActive ? 'teal.500' : 'white'}
          borderRadius='100%'
          mr='20px'
          px='20px'
          py='10px'
        >
          {number}
        </Box>
        <Text>{title}</Text>
      </Flex>
      <Description
        ml='70px'
        pb={lastStep ? '56px' : '48px'}
        position='relative'
      >
        {!lastStep && (
          <Box
            backgroundColor='white'
            h='calc(100% - 16px)'
            left='-45px'
            position='absolute'
            top='8px'
            w='1px'
          />
        )}
        {description}
        {link ? (
          <Box color='white.100' textDecoration='underline'>
            {link}
          </Box>
        ) : null}
      </Description>
    </Box>
  );
};

export default ProgressStep;
