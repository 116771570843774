import { FC } from 'react';

import { AccordionProps } from '@chakra-ui/react';

import Accordion from '@/components/Accordion/Accordion';

const BloodTestFAQAccordion: FC<AccordionProps> = (props) => {
  return (
    <Accordion
      items={[
        {
          title: 'Where can I get my blood tests done?',
          content: [
            'Ted’s Health will send a blood testing kit to your home, so there’s no need to make an appointment or find time to visit a clinic.',
          ],
        },
        {
          title: 'How much do blood tests cost?',
          content: [
            'Your blood test will cost £50, which includes results and a follow up video consultation with one of our doctors.',
          ],
        },
        {
          title: 'When will my results be available?',
          content: [
            'Once your sample reaches our lab, it will take around five working days to process. We’ll keep you updated via your bespoke online treatment dashboard.',
          ],
        },
        {
          title: 'How can I view my results?',
          content: [
            'Simply click on your treatment dashboard in the login area to view your blood test results.',
          ],
        },
        {
          title: 'What hormones do you test for?',
          content: [
            'First blood test - Total testosterone',
            'Confirmatory blood test - Total testosterone, Free Testosterone, Luteneising Hormone (LH), Prolactin, Prostate-specific Antigen (PSA), Full blood count (FBC) HbA1C, Liver biomarkers (ALT + AST)',
            'Monitoring tests - Total testosterone, Full blood count, Prostate-specific Antigen, HbA1C, Liver biomarkers (ALT + AST)',
          ],
        },
        {
          title: 'What time should I take my blood test?',
          content: [
            'Ideally, you should do your test before 11:00 am, as hormone levels are highest at this time. For the most accurate reading, you should fast 10-12 hours leading up to this time.',
          ],
        },
      ]}
      {...props}
    />
  );
};

export default BloodTestFAQAccordion;
