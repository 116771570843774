import { FC, SVGProps } from 'react';

const BodyFatIcon: FC<SVGProps<SVGElement>> = ({
  width = '194',
  height = '194',
}) => {
  return (
    <svg
      fill='none'
      height={height}
      viewBox='0 0 194 194'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_728_3517)'>
        <rect
          fill='#1F1E1E'
          height='121.129'
          transform='rotate(-45 11 97)'
          width='121.129'
          x='11'
          y='97'
        />
        <rect
          height='120.031'
          stroke='#009FAF'
          strokeWidth='1.09809'
          transform='rotate(-45 11.7765 97)'
          width='120.031'
          x='11.7765'
          y='97'
        />
      </g>
      <path
        d='M88.0986 87.1025C88.0986 81.941 92.2274 77.755 97.3183 77.755C102.409 77.755 106.538 81.941 106.538 87.1025H88.0986Z'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <path
        d='M100.245 82.3882L97.3574 87.1025'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <path
        d='M112.79 121.688C112.79 121.688 113.752 114.292 115.196 110.268C116.639 106.285 116.719 101.408 115.196 99.6608C113.672 97.9132 107.7 92.9956 107.7 92.9956C107.7 92.9956 105.775 90.5165 103.851 92.9956C101.927 95.4747 101.005 98.279 100.965 99.5388C100.885 102.668 102.969 109.496 102.729 112.869C102.488 116.12 102.007 120.835 99.1213 121.688H95.6338C92.7477 120.835 92.2667 116.12 92.0262 112.869C91.7856 109.537 93.8701 102.668 93.7899 99.5388C93.7498 98.279 92.8279 95.4341 90.9038 92.9956C88.9797 90.5571 87.0556 92.9956 87.0556 92.9956C87.0556 92.9956 81.0828 97.9132 79.5596 99.6608C78.0363 101.408 78.1165 106.285 79.5596 110.268C81.0026 114.251 81.9647 121.688 81.9647 121.688H78.3971C75.7915 121.688 73.667 119.534 73.667 116.893V77.3081C73.667 74.6664 75.7915 72.5125 78.3971 72.5125H116.278C118.883 72.5125 121.008 74.6664 121.008 77.3081V116.893C121.008 119.534 118.883 121.688 116.278 121.688H112.79Z'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <defs>
        <filter
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
          height='193.264'
          id='filter0_d_728_3517'
          width='193.264'
          x='0.0190735'
          y='0.36795'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='5.49046' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.054902 0 0 0 0 0.819608 0 0 0 0 0.984314 0 0 0 0.7 0'
          />
          <feBlend
            in2='BackgroundImageFix'
            mode='normal'
            result='effect1_dropShadow_728_3517'
          />
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_728_3517'
            mode='normal'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BodyFatIcon;
