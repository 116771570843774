import { FC, SVGProps } from 'react';

const FacebookIcon: FC<SVGProps<SVGElement>> = ({
  width = 32,
  height = 32,
  color = '#6F6F6F',
}) => {
  return (
    <svg
      fill='none'
      height={height}
      viewBox='0 0 32 32'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM17.6715 16.7028V25.4076H14.0699V16.7031H12.2705V13.7034H14.0699V11.9024C14.0699 9.4552 15.0859 8 17.9726 8H20.3759V11.0001H18.8737C17.75 11.0001 17.6756 11.4193 17.6756 12.2017L17.6715 13.7031H20.3929L20.0745 16.7028H17.6715Z'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  );
};

export default FacebookIcon;
