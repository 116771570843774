import React from 'react';

import Carousel from '@/components/Carousel/Carousel';
import CustomerReview from '@/components/CustomerReview/CustomerReview';

const CustomerReviews = () => {
  return (
    <Carousel sx={{ '.slick-slider': { width: '100vw' } }}>
      <CustomerReview
        key='1'
        age={40}
        imgSource='images/profile-avatar-1.png'
        name='Patient A'
        review='“I have had no side effects. I can say that after first injections, a few weeks later I felt great. The Nebido together with tadalafil have completely reversed all signs of ED and for the majority of the period between injections libido is very good.”'
      />
      <CustomerReview
        key='2'
        age={38}
        imgSource='images/profile-avatar-2.png'
        name='Patient B'
        rating={4}
        review={'“The meds are working GREAT ! It\'s not only fixed the ED issues, but I no longer get tired during the day and the pounding heart during sex has reduced dramatically.”'}
      />
      <CustomerReview
        key='3'
        age={42}
        imgSource='images/profile-avatar-3.png'
        name='Patient C'
        rating={5}
        review='“I really appreciated you taking the time to explain everything so clearly and your personable nature in discussing what for anyone is a very sensitive issue. If they did, we’d have a more knowledgeable and healthier society”'
      />
      <CustomerReview
        key='4'
        age={28}
        imgSource='images/profile-avatar-1.png'
        name='Patient D'
        rating={5}
        review='“I just wanted to say I’m very thankful for your help that you gave me. It really changed/ saved my life.”'
      />
      <CustomerReview
        key='5'
        age={32}
        imgSource='images/profile-avatar-2.png'
        name='Patient E'
        rating={5}
        review='“Thank you for all your help and support throughout the year and your expert advice. I am now feeling better than I have done for years and for that I thank you.”'
      />
      <CustomerReview
        key='6'
        age={36}
        imgSource='images/profile-avatar-3.png'
        name='Patient F'
        rating={5}
        review={'“I just wanted to take a minute to say thanks for all your help these past few years. It\'s been refreshing to engage with a doctor who has an open mind regarding what is best for each person.”'}
      />
      <CustomerReview
        key='7'
        age={43}
        imgSource='images/profile-avatar-1.png'
        name='Patient G'
        rating={5}
        review='“Myself and my wife would like to thank you again for the difference your help has made. It is very much appreciated.”'
      />
      <CustomerReview
        key='8'
        age={48}
        imgSource='images/profile-avatar-2.png'
        name='Patient H'
        rating={5}
        review='“You truly have been a beacon of light at the end of a very dark tunnel. Countless consultations spanning a decade, dismissive of my predicament in every instance for having low but borderline testosterone. Therefore, your proposal to structure a treatment plan is most appreciated.”'
      />
      <CustomerReview
        key='9'
        age={55}
        imgSource='images/profile-avatar-3.png'
        name='Patient I'
        rating={5}
        review='“I can report that I feel 20 years younger at the moment, energy and concentration levels are also great.”'
      />
    </Carousel>
  );
};

export default CustomerReviews;
