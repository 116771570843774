import { FC } from 'react';

import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
  Icon,
} from '@chakra-ui/react';

import ChevronDown from '@/components/Icons/ChevronDown';
import { H2, P } from '@/elements';

interface IItem {
  title: string;
  content: string[];
}

const Item = ({ title, content }: IItem) => {
  return (
    <AccordionItem borderColor='rgba(215, 203, 195, 0.1)'>
      <H2>
        <AccordionButton
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: 'transparent', color: 'teal.400' }}
          color='white.100'
          pl={0}
          py={{ xs: 'semiLg', md: 'lg', lg: '40px' }}
        >
          <Box
            flex={1}
            fontSize={{ xs: 'md', md: '22px' }}
            fontWeight='300'
            textAlign='left'
          >
            {title}
          </Box>
          <Icon as={ChevronDown as any} />
        </AccordionButton>
      </H2>
      <AccordionPanel pb='md' pt={0} px={0}>
        {content.map((i) => (
          <P key={i} mt='sm'>{i}</P>
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

const Accordion: FC<{ items: IItem[] } & AccordionProps> = ({
  items,
  ...rest
}) => {
  return (
    <ChakraAccordion allowMultiple allowToggle {...rest}>
      {items.map((i) => (
        <Item key={i.title} content={i.content} title={i.title} />
      ))}
    </ChakraAccordion>
  );
};

export default Accordion;
