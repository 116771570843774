import { FC, SVGProps } from 'react';

const TwitterIcon: FC<SVGProps<SVGElement>> = ({
  width = 32,
  height = 32,
  color = '#6F6F6F',
}) => {
  return (
    <svg
      fill='none'
      height={height}
      viewBox='0 0 32 32'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0ZM15.5544 13.5564L15.5208 13.0028C15.4201 11.5678 16.3042 10.2572 17.7032 9.74876C18.218 9.56798 19.0909 9.54538 19.6617 9.70356C19.8856 9.77136 20.3108 9.99733 20.613 10.2007L21.1614 10.5736L21.7658 10.3815C22.1015 10.2798 22.5492 10.1103 22.7506 9.99733C22.9409 9.89564 23.1088 9.83915 23.1088 9.87304C23.1088 10.0651 22.6947 10.7204 22.3477 11.082C21.8777 11.5904 22.012 11.6356 22.9633 11.2967C23.534 11.1046 23.5452 11.1046 23.4333 11.3193C23.3662 11.4323 23.0192 11.8277 22.6499 12.1893C22.0232 12.8107 21.9896 12.8785 21.9896 13.3982C21.9896 14.2004 21.6091 15.8726 21.2286 16.7878C20.5235 18.5052 19.0126 20.2791 17.5017 21.1717C15.3753 22.4259 12.5438 22.7423 10.16 22.0078C9.36538 21.7593 8 21.1265 8 21.0136C8 20.9797 8.41409 20.9345 8.91772 20.9232C9.96973 20.9006 11.0218 20.6068 11.9171 20.0871L12.5214 19.7255L11.8276 19.4882C10.8427 19.1493 9.95854 18.3697 9.73471 17.6352C9.66756 17.398 9.68994 17.3867 10.3167 17.3867L10.9658 17.3754L10.4174 17.1155C9.76828 16.7878 9.17513 16.2342 8.88414 15.6693C8.6715 15.2625 8.4029 14.2343 8.48124 14.1552C8.50362 14.1214 8.73865 14.1891 9.00725 14.2795C9.77948 14.562 9.8802 14.4942 9.43253 14.0197C8.59316 13.161 8.33575 11.8842 8.73865 10.6753L8.92891 10.1329L9.66756 10.8673C11.1784 12.3475 12.9579 13.2288 14.9948 13.4886L15.5544 13.5564Z'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  );
};

export default TwitterIcon;
