import React, { useEffect } from 'react';

import { Box, Flex, Container } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import Benefits from '@/components/LandingPage/Benefits';
import BloodTestFAQAccordion from '@/components/LandingPage/BloodTestFAQAccordion';
import CustomerReviews from '@/components/LandingPage/CustomerReviews';
import FAQAccordion from '@/components/LandingPage/FAQAccordion';
import GetStarted from '@/components/LandingPage/GetStarted';
import Specialists from '@/components/LandingPage/Specialists';
import Symptoms from '@/components/LandingPage/Symptoms';
import Treatments from '@/components/LandingPage/Treatments';
import Video from '@/components/LandingPage/Video';
import WhatToExpect from '@/components/LandingPage/WhatToExpect';
import { Section } from '@/components/Layout';
import Page from '@/components/Pages/Page';
import { BigTitle, H2, Button, Subtitle } from '@/elements';

const Home = (): JSX.Element => {
  const { push, prefetch } = useRouter();

  useEffect(() => {
    prefetch('/checkout/trt-kit-and-consultation');
  }, []);

  const startNow = () => {
    push('/checkout/trt-kit-and-consultation');
  };

  return (
    <Page withFooter withHeader>
      <Video />

      <Section id='get-started'>
        <GetStarted startNow={startNow} />
      </Section>

      <Section mb='xxxl'>
        <Container maxW='818px'>
          <BigTitle
            fontSize={{ xs: '44px', md: '58px', lg: 'xxl' }}
            maxW='729px'
            mb='72px'
          >
            The reassuring{' '}
            <Box color='teal.500' display='inline-flex'>
              benefits
            </Box>{' '}
          </BigTitle>
          <Flex flexDir='column' maxW='818px'>
            <Benefits />
            <Button
              alignSelf='flex-end'
              onClick={startNow}
              w={{
                base: '100%',
                sm: '380px',
              }}
            >
              Start now
            </Button>
          </Flex>
        </Container>
      </Section>

      <Section id='symptoms' mb='xxxl'>
        <Symptoms />
      </Section>

      <Section contentProps={{ maxWidth: 'auto' }} px={0}>
        <Treatments />
        <WhatToExpect />
      </Section>

      <Section mb='xxxl'>
        <Subtitle fontSize={{ xs: '14px', md: 'base' }} mb='32px'>
          REVIEWS
        </Subtitle>
        <H2 fontSize={{ xs: '32px', md: '40px', lg: 'xl' }} mb='72px'>
          What patients say?
        </H2>
        <CustomerReviews />
      </Section>

      <Section mb='xxl' textAlign={{ xs: 'start', md: 'center' }}>
        <Subtitle fontSize={{ xs: '14px', md: 'base' }} mb='lg'>
          MEDICAL TEAM
        </Subtitle>
        <H2
          fontSize={{ xs: '44px', md: '58px', lg: 'xxl' }}
          mb={{ xs: 'xl', md: 'xxl' }}
        >
          Our specialists
        </H2>
        <Specialists />
      </Section>

      <Section id='faq' mb='xxl'>
        <H2 fontSize={{ xs: '32px', md: '40px', lg: 'xl' }} mb='lg'>
          FAQ
        </H2>
        <FAQAccordion />
      </Section>

      <Section id='blood-test-faq' mb='xxl'>
        <H2 fontSize={{ xs: '32px', md: '40px', lg: 'xl' }} mb='lg'>
          Blood Test FAQ
        </H2>
        <BloodTestFAQAccordion />
      </Section>
    </Page>
  );
};

export default Home;
