import React, { FC } from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { P } from '@/elements';

interface IBenefitProps {
  align: string;
  title: string;
  description: string;
}

const Benefit: FC<IBenefitProps> = ({ align, title, description }) => {
  return (
    <Flex
      alignSelf={align}
      flexDir='row'
      maxW={{ base: '100%', sm: '380px' }}
      mb='72px'
    >
      <Box backgroundColor='teal.500' height='64px' mr='34px' w='4' />
      <Flex flexDir='column'>
        <Text fontSize='20px' lineHeight='24px' mb='16px'>
          {title}
        </Text>
        <P>{description}</P>
      </Flex>
    </Flex>
  );
};

export default Benefit;
