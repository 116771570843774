import { FC, SVGProps } from 'react';

const InsufficientHairlineIcon: FC<SVGProps<SVGElement>> = ({
  width = '194',
  height = '194',
}) => {
  return (
    <svg
      fill='none'
      height={height}
      viewBox='0 0 194 194'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_728_3490)'>
        <rect
          fill='#1F1E1E'
          height='121.129'
          transform='rotate(-45 11 97)'
          width='121.129'
          x='11'
          y='97'
        />
        <rect
          height='120.031'
          stroke='#009FAF'
          strokeWidth='1.09809'
          transform='rotate(-45 11.7765 97)'
          width='120.031'
          x='11.7765'
          y='97'
        />
      </g>
      <path
        d='M109.931 89.7452H127.111V114.037H64.8887V89.7452H82.0689'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <path
        d='M99.5602 101.96C99.5602 103.921 97.9701 105.538 95.9652 105.538C93.9603 105.538 92.3701 103.956 92.3701 101.96'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <path
        d='M92.4053 101.96C93.3386 85.995 103.882 62.1852 103.882 62.1852C95.3089 83.965 99.5608 101.96 99.5608 101.96'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <path
        d='M103.328 101.891C103.328 105.917 100.044 109.185 95.9993 109.185C91.9548 109.185 88.6709 105.917 88.6709 101.891'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <path
        d='M88.6362 101.891C88.6362 101.891 88.6708 99.7922 89.4313 98.5879C90.1918 97.3837 90.814 89.7452 82.0684 89.7452'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <path
        d='M103.329 101.891C103.329 101.891 103.295 99.7922 102.534 98.5879C101.774 97.3837 101.151 89.7452 109.897 89.7452'
        stroke='#009FAF'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='1.64714'
      />
      <defs>
        <filter
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
          height='193.264'
          id='filter0_d_728_3490'
          width='193.264'
          x='0.0190735'
          y='0.36795'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='5.49046' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.054902 0 0 0 0 0.819608 0 0 0 0 0.984314 0 0 0 0.7 0'
          />
          <feBlend
            in2='BackgroundImageFix'
            mode='normal'
            result='effect1_dropShadow_728_3490'
          />
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_728_3490'
            mode='normal'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default InsufficientHairlineIcon;
